<template>
  <div class="oldBrand-add">
    <GlobalChunk icon="add" title="添加收货打款" :padding="[32, 0, 95, 40]">
      <el-row>
        <el-col :span="10">
          <el-form ref="ruleform" :model="initData" :rules="formRules">
            <el-form-item
              label="收款商户类型"
              label-width=""
              prop="businessType"
            >
              <el-radio-group
                v-model="initData.businessType"
                @input="merchchange"
              >
                <el-radio label="01">门店商</el-radio>
                <el-radio label="02">回收商</el-radio>
                <el-radio label="03">其他</el-radio>
              </el-radio-group>
            </el-form-item>
            <div class="name_merchat">
              <el-form-item
                v-if="initData.businessType == '02'"
                label="收款商户"
                prop="merchantId"
              >
                <el-select
                  ref="select1"
                  v-model="initData.merchantId"
                  placeholder="请输入查询或选择收款商户"
                  filterable
                  clearable
                  @change="handleChange"
                  @hook:mounted="cancalReadOnly"
                  @visible-change="cancalReadOnly"
                >
                  <el-option
                    v-for="item in shanpsSelectList"
                    :key="item.merchantId"
                    :label="item.merchantName"
                    :value="item.merchantId"
                  ></el-option>
                </el-select>
                <span class="textstyle" @click="Actionpayment"
                  >使用近期打款账户</span
                >
              </el-form-item>
              <el-form-item
                v-if="initData.businessType == '01'"
                label="收款商户"
                prop="companyId"
              >
                <el-select
                  ref="select1"
                  v-model="initData.companyId"
                  placeholder="请输入查询或选择收款商户"
                  filterable
                  clearable
                  @change="storehandleChange"
                >
                  <el-option
                    v-for="item in storeoption"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
                <span class="textstyle" @click="Actionpayment"
                  >使用近期打款账户</span
                >
              </el-form-item>
              <el-form-item
                v-if="initData.businessType == '03'"
                label="收款商户"
                prop="otherName"
              >
                <el-input
                  placeholder="请输入收款商户"
                  v-model.trim="initData.otherName"
                ></el-input>
                <span class="textstyle" @click="Actionpayment"
                  >使用近期打款账户</span
                >
              </el-form-item>
            </div>
            <el-form-item label="收款账户类型" prop="arrivalMethod">
              <el-radio-group
                v-model="initData.arrivalMethod"
                @input="coolactionchange"
              >
                <el-radio label="02">银行卡</el-radio>
                <el-radio label="01">支付宝</el-radio>
              </el-radio-group>
            </el-form-item>
            <div style="margin-left:20px" class="name_merchat">
              <el-form-item
                label="银行卡类型"
                prop="publicType"
                v-if="initData.arrivalMethod == '02'"
              >
                <el-radio-group
                  v-model="initData.publicType"
                  @input="banckactionchange"
                >
                  <el-radio label="1">对公账户</el-radio>
                  <el-radio label="2">个人私卡</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="收款人姓名"
                prop="alipayUserName"
                v-if="initData.arrivalMethod == '01'"
              >
                <el-input
                  placeholder="请输入收款人姓名"
                  v-model.trim="initData.alipayUserName"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="支付宝账户"
                prop="alipayAccount"
                v-if="initData.arrivalMethod == '01'"
              >
                <el-input
                  placeholder="请输入支付宝账户"
                  v-model.trim="initData.alipayAccount"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="持卡人"
                prop="bankCardholder"
                v-if="initData.arrivalMethod == '02'"
              >
                <el-input
                  placeholder="请输入持卡人"
                  v-model.trim="initData.bankCardholder"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="收款卡号"
                prop="bankCardCode"
                v-if="initData.arrivalMethod == '02'"
              >
                <el-input
                  placeholder="请输入收款卡号"
                  v-model.trim="initData.bankCardCode"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="所属银行"
                prop="bankCode"
                v-if="initData.arrivalMethod == '02'"
              >
                <el-select
                  v-model="initData.bankCode"
                  placeholder="请输入查询或选择所属银行"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in banckList"
                    :key="item.code"
                    :label="item.alipayBank"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="开户行"
                prop="bankOpening"
                v-if="
                  initData.arrivalMethod == '02' && initData.publicType == '1'
                "
              >
                <el-input
                  placeholder="请输入准确开户行分支行全称"
                  v-model.trim="initData.bankOpening"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="到账时间"
                label-width=""
                prop="arrivalMoment"
                v-if="initData.arrivalMethod == '02'"
              >
                <el-radio-group v-model="initData.arrivalMoment">
                  <el-radio label="01">实时到账</el-radio>
                  <el-radio label="02">次日到账（节假日顺延）</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
             <el-form-item label="打款金额" prop="amount">
              <el-input
                placeholder="请输入打款金额"
                @input="limitInput($event, 'amount')"
                v-model.trim="initData.amount"
              ></el-input>
              <span v-if="NumMoney" style="color:#F83232;font-weight: bold;">{{
                NumMoney
              }}</span>
             </el-form-item>
             <div class="name_merchat">
              <el-form-item label="打款凭证" prop="payVoucherImg">
              <ElImgUploadGroup
                place-txt="请上传本次的打款凭证（限3张内，大小不超过2M，格式为JPG或PNG）"
                :length="3"
                :exist-img-list="initData.payVoucherImg"
                @handleChange="handleUploadGroupChange($event)"
              />
            </el-form-item>
            <el-form-item label="打款原因" prop="payNote">
              <el-input
                maxlength="60"
                type="textarea"
                placeholder="请输入原因说明（限60字）"
                v-model="initData.payNote"
              ></el-input>
            </el-form-item>
             </div>
            <el-form-item>
              <el-button @click="FromBack">返回</el-button>
              <el-button type="primary" @click="handleConfirm('ruleform')"
                >立即创建</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </GlobalChunk>
    <!-- 动态口令弹出框 -->
    <el-dialog
      title="打款提示"
      :visible.sync="commandShow"
      :close-on-click-modal="false"
      width="420px"
      @closed="commandClosed"
    >
      <div class="sure-tip">
        <span>说明：打款后将无法撤销，请务必核实清楚收款人及收款卡号</span>
      </div>
      <div class="amount-price">
        <span
          >是否确认打款<span style="color:red">{{ params.amount }}元</span>给{{
            dakuabName
          }}？</span
        >
      </div>
      <div class="command">
        <div style="width: max-content;">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="submit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 动态口令弹出框 -->
    <el-dialog
      title="打款提示"
      :visible.sync="susessShow"
      :close-on-click-modal="false"
      width="420px"
      @closed="closeallsusess"
    >
      <div class="sure-tip">
        <span
          >说明：您选择的方式是次日到账，该笔款项将于次日凌晨自动打款，您可在打款列表中查看汇款明细</span
        >
      </div>
      <div class="amount-price" style="font-size: 16px">
        <span>打款已创建完成!</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          :loading="butLoading"
          @click.native="closeallsusess"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 近期打款账户弹出框 -->
    <el-dialog
      title="近期打款账户"
      :visible.sync="AccountShow"
      :close-on-click-modal="false"
      width="600px"
      @closed="AccountShow = false"
    >
      <div class="ac_allcontent">
        <div v-if="frequentlyusedData.length > 0">
          <div
            class="aucont_content"
            v-for="(item, index) in frequentlyusedData"
            :key="index"
          >
            <div>
              <p v-if="item.type == '01'" style="margin-bottom: 10px;">
                支付宝账户：{{ item.alipayAccount }}
              </p>
              <p v-else style="margin-bottom: 10px;">
                银行卡号：{{ item.bankCardCode }}
                {{ "（" + item.bankName + "）" }}
              </p>
              <p>
                收款人：{{
                  item.type == "01" ? item.alipayUserName : item.bankCardholder
                }}
              </p>
            </div>
            <el-button type="warning" size="small" @click="useQuently(item)"
              >使用</el-button
            >
          </div>
        </div>
        <div v-else style="height: 80px;text-align: center;line-height: 80px;">
          暂无打款成功账户信息
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ElImgUploadGroup from "../../../components/global/components/elements/upload-group-element.vue";
import _api from "@/utils/request";
export default {
  name: "oldBrand-add",
  components: { ElImgUploadGroup },
  data() {
    return {
      frequentlyusedData: [], //近期打款账户列表
      AccountShow: false,
      dakuabName: "",
      banckList: [],
      storeoption: [],
      butLoading: false,
      initData: {
        alipayUserName: "",
        bankCardholder: "",
        bankOpening: "",
        bankCardCode: "",
        alipayAccount: "",
        businessType: "01",
        otherName: "",
        merchantId: "",
        companyId: "",
        bankCode: '',
        amount: "",
        payVoucherImg: [],
        payNote: "",
        arrivalMoment: "01",
        arrivalMethod: "02",
        publicType: '1',
      },
      susessShow: false,
      commandShow: false,
      accountType: "",
      command: "",
      shanpsSelectList: [],
      staffList: [],
      params: {},
      NumMoney: "",
      formRules: {
        companyId: [
          { required: true, message: "请选择收款商户", trigger: "change" },
        ],
        merchantId: [
          { required: true, message: "请选择收款商户", trigger: "change" },
        ],
        alipayUserName: [
          { required: true, message: "请输入收款人姓名", trigger: "blur" },
        ],
        alipayAccount: [
          { required: true, message: "请输入支付宝账户", trigger: "blur" },
        ],
        otherName: [
          { required: true, message: "请输入收款商户", trigger: "blur" },
        ],
        bankCardholder: [
          { required: true, message: "请输入持卡人", trigger: "blur" },
        ],
        bankCardCode: [
          { required: true, message: "请输入收款卡号", trigger: "blur" },
        ],
        bankCode: [
          { required: true, message: "请选择所属银行", trigger: "change" },
        ],
        bankOpening: [
          {
            required: true,
            message: "请输入准确开户行分支行全称",
            trigger: "blur",
          },
        ],
        publicType: [
          {
            required: true,
            message: "请选择收款银行卡类型",
            trigger: "change",
          },
        ],
        arrivalMethod: [
          { required: true, message: "请选择收款账户类型", trigger: "change" },
        ],
        businessType: [
          { required: true, message: "请选择收款商户类型", trigger: "change" },
        ],
        arrivalMoment: [
          { required: true, message: "请选择到账时间", trigger: "change" },
        ],
        amount: [
          {
            required: true,
            trigger: "blur",
            message: "输入金额",
          },
        ],
        payVoucherImg: [
          {
            required: true,
            trigger: "change",
            message: "请上传图片",
          },
        ],
        payNote: [
          {
            required: true,
            trigger: "blur",
            message: "输入打款原因（60字内）",
          },
        ],
      },
    };
  },
  created() {
    this.getMerchantList();
    this.storeSelectList();
    this.getBancklist();
    console.log(this.changeNumMoneyToChinese(2001.22));
  },
  methods: {
    useQuently(row) {
      console.log(row);
      this.initData.arrivalMethod = row.type;
      //type	类型 01 支付宝 02 银行卡
      if (row.type == "01") {
        this.initData.alipayUserName = row.alipayUserName;
        this.initData.alipayAccount = row.alipayAccount;
      } else {
        this.initData.bankCardholder = row.bankCardholder;
        this.initData.bankCardCode = row.bankCardCode;
        this.initData.bankCode = row.bankCode;
        this.initData.bankOpening = row.bankOpening;
        //publicType  1对公 2对私
        this.initData.publicType = row.publicType;
      }
      this.AccountShow = false;
    },
    // 上传图片后，将图片信息保存到对应参数中
    handleUploadGroupChange(list) {
      console.log(list, this.initData.payVoucherImg);
    },
    resetForm(formName) {
      this.$refs[formName].clearValidate();
    },
    getBancklist() {
      _api.getBankSelect().then((res) => {
        console.log(res);
        this.banckList = res.data || [];
      });
    },
    //使用近期打款账户
    Actionpayment() {
      console.log(this.initData);
      let rguParams = {
        arrivalMethod: "",
        businessType: this.initData.businessType,
        companyId: "",
        merchantId: "",
        otherName: "",
      };
      if (this.initData.businessType == "01") {
        rguParams.companyId = this.initData.companyId;
      } else if (this.initData.businessType == "02") {
        rguParams.merchantId = this.initData.merchantId;
      } else {
        rguParams.otherName = this.initData.otherName;
      }
      _api.Regulardebitaccount(rguParams).then((res) => {
        if (res.code == 1) {
          this.AccountShow = true;
          this.frequentlyusedData = res.data;
        }
      });
    },
    //收款商户类型
    merchchange(e) {
      console.log(e);
      if (e == "01") {
        this.initData.merchantId = "";
        this.initData.otherName = "";
      } else if (e == "02") {
        this.initData.companyId = "";
        this.initData.otherName = "";
      } else {
        this.initData.merchantId = "";
        this.initData.companyId = "";
      }
    },
    //收款账户类型
    coolactionchange(e) {
      if (e == "02") {
        this.initData.alipayUserName = "";
        this.initData.alipayAccount = "";
      } else if (e == "01") {
        this.initData.bankCardholder = "";
        this.initData.bankCode = "";
        this.initData.bankCardCode = "";
        this.initData.bankOpening = "";
        this.initData.arrivalMoment = "";
        this.initData.publicType = "";
      }
      this.resetForm("ruleform");
    },
    //银行卡类型
    banckactionchange(e) {
      if (e == 1) {
        console.log(e);
      } else if (e == 2) {
        this.initData.bankOpening = "";
      }
      this.resetForm("ruleform");
    },
    // 门店下拉
    storeSelectList() {
      _api.getSelectList().then((res) => {
        console.log(res);
        this.storeoption = res.data;
      });
    },
    // 获取回收商
    getMerchantList() {
      const params = {
        pageNum: 0,
        pageSize: 999,
      };
      _api.listSimpleMerchantView(params).then((res) => {
        if (res.code === 1) {
          this.shanpsSelectList = res.data;
        }
      });
    },
    handleConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.initData, this.dakuabName);
          if (this.initData.businessType == "01") {
            this.storeoption.forEach((item) => {
              if (item.id == this.initData.companyId) {
                this.dakuabName = item.name;
              }
            });
          } else if (this.initData.businessType == "02") {
            this.shanpsSelectList.forEach((item) => {
              if (item.merchantId == this.initData.merchantId) {
                this.dakuabName = item.merchantName;
              }
            });
          } else {
            this.dakuabName = this.initData.otherName;
          }
          this.params = this.initData;
          this.commandShow = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    storehandleChange(val) {
      this.initData.companyId = val;
    },
    handleChange(val) {
      this.initData.merchantId = val;
      this.initData.bankCode = "";
      this.initData.contactsPhone = "";
      if (val) {
        this.getStaffList();
      } else {
        this.staffList = [];
      }
    },
    // contactsHandleChange(val) {
    //   if (val) {
    //     this.staffList.forEach((item) => {
    //       if (item.staffId == val) {
    //         this.initData.bankCode = item.staffName;
    //         this.initData.contactsPhone = item.staffPhone;
    //       }
    //     });
    //   } else {
    //     this.initData.bankCode = "";
    //     this.initData.contactsPhone = "";
    //   }
    // },
    FromBack() {
      this.$router.go(-1);
    },
    commandClosed() {
      this.commandShow = false;
      this.butLoading = false;
      this.command = "";
    },
    submit() {
      let params = {
        command: this.command,
        ...this.params,
      };
      this.butLoading = true;
      _api
        .foreignbuild(params)
        .then((res) => {
          if (res.code === 1) {
            if (this.params.arrivalMethod == "02") {
              if (this.params.arrivalMoment == "01") {
                this.$message({
                  message: "打款成功",
                  type: "success",
                });
                this.$router.push({ path: "/payment/ReceivePayment" });
              } else {
                this.susessShow = true;
              }
            } else {
              this.$message({
                message: "打款成功",
                type: "success",
              });
              this.$router.push({ path: "/payment/ReceivePayment" });
            }
          } else {
            this.$message({
              message: res.msg || "打款失败",
              type: "error",
            });
          }
          this.butLoading = false;
        })
        .catch((err) => {
          this.butLoading = false;
        });
    },
    closeallsusess() {
      this.susessShow = false;
      this.$router.push({ path: "/payment/ReceivePayment" });
    },
    limitInput(value, name) {
      console.log(value);
      let str = (value && value.split("")) || [];
      let reg1 = /\d/;
      let reg2 = /\./;
      // 第一个字符不能为小数点
      if (str[0] == ".") {
        this.initData[name] = "";
        return;
      }
      // 过滤掉除数字和小数点外的字符
      value = str.filter((e) => reg1.test(e) || reg2.test(e));
      // 匹配小数点后只能有两位小数
      let valJoin = value.join("");
      this.initData[name] = valJoin.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      this.NumMoney = valJoin.match(/^\d*(\.?\d{0,2})/g)[0]
        ? this.changeNumMoneyToChinese(valJoin.match(/^\d*(\.?\d{0,2})/g)[0])
        : "";
    },
    // 购买人（使用人）列表
    getStaffList() {
      const params = {
        merchantId: this.initData.merchantId,
      };
      _api.recycleStaffSelect(params).then((res) => {
        if (res.code === 1) {
          this.staffList = res.data;
        }
      });
    },
    changeNumMoneyToChinese(money) {
      var cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
      ); //汉字的数字
      var cnIntRadice = new Array("", "拾", "佰", "仟"); //基本单位
      var cnIntUnits = new Array("", "万", "亿", "兆"); //对应整数部分扩展单位
      var cnDecUnits = new Array("角", "分", "毫", "厘"); //对应小数部分单位
      var cnInteger = "整"; //整数金额时后面跟的字符
      var cnIntLast = "元"; //整型完以后的单位
      var maxNum = 999999999999999.9999; //最大处理的数字
      var IntegerNum; //金额整数部分
      var DecimalNum; //金额小数部分
      var ChineseStr = ""; //输出的中文金额字符串
      var parts; //分离金额后用的数组，预定义
      var Symbol = ""; //正负值标记
      if (money == "") {
        return "";
      }

      money = parseFloat(money);
      if (money >= maxNum) {
        alert("超出最大处理数字");
        return "";
      }
      if (money == 0) {
        ChineseStr = cnNums[0] + cnIntLast + cnInteger;
        return ChineseStr;
      }
      if (money < 0) {
        money = -money;
        Symbol = "负 ";
      }
      money = money.toString(); //转换为字符串
      if (money.indexOf(".") == -1) {
        IntegerNum = money;
        DecimalNum = "";
      } else {
        parts = money.split(".");
        IntegerNum = parts[0];
        DecimalNum = parts[1].substr(0, 4);
      }
      if (parseInt(IntegerNum, 10) > 0) {
        //获取整型部分转换
        var zeroCount = 0;
        var IntLen = IntegerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = IntegerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              ChineseStr += cnNums[0];
            }
            zeroCount = 0; //归零
            ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            ChineseStr += cnIntUnits[q];
          }
        }
        ChineseStr += cnIntLast;
        //整型部分处理完毕
      }
      if (DecimalNum != "") {
        //小数部分
        var decLen = DecimalNum.length;
        for (let i = 0; i < decLen; i++) {
          let n = DecimalNum.substr(i, 1);
          if (n != "0") {
            ChineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (ChineseStr == "") {
        ChineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (DecimalNum == "") {
        ChineseStr += cnInteger;
      }
      ChineseStr = Symbol + ChineseStr;

      return ChineseStr;
    },
    cancalReadOnly(value) {
      this.$nextTick(() => {
        if (!value) {
          const { select1 } = this.$refs;
          const input = select1.$el.querySelector(".el-input__inner");
          input.removeAttribute("readonly");
          // this.$refs.select.blur();  根据tip自行判断是否添加
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.oldBrand-add {
  .el_from {
    margin-left: -120px;
    margin-bottom: 0;
  }
  /deep/.el-select {
    width: 100% !important;
  }
  .command {
    display: flex;
    align-items: center;
    /deep/.el-input {
      width: 300px;
    }
  }
  .amount-price {
    text-align: center;
    font-size: 15px;
    margin: 15px 0;
  }
  .sure-tip {
    color: rgb(255, 104, 123);
    font-size: 14px;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.textstyle {
  min-width: 120px;
  font-size: 14px;
  margin-left: 20px;
  font-weight: 400;
  color: #0981ff;
  text-decoration: underline;
  cursor: pointer;
}
/deep/.el-form-item {
  display: flex;
}
.name_merchat {
  /deep/.el-form-item__content {
    flex: 1;
    display: flex;
    align-items: center;
  }
}
.aucont_content {
  background: #f5f6fa;
  height: 80px;
  padding: 0 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ac_allcontent {
  max-height: 500px;
  overflow-y: auto;
  padding-bottom: 20px;
}
</style>
